.footer {
  position: fixed; 
  bottom: 0; 
  width: 100%; 
  text-align: center;
  padding: 1rem;
  background-color: #2135eb; 
}

.footer p {
  color: #ffffff; /* Sets the text color to dark gray */
  margin: 0; /* Removes default margin for cleaner styling */
}
