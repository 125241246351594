.onboarding-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  input[type="email"],
  input[type="text"],
  select,
  button {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }
  
  .team-member {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  
  .team-member input[type="email"],
  .team-member input[type="text"] {
    width: 100%;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  .payment-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 10%;
  }
  
  .payment-section h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #000000;
  }
  
  .payment-section div {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .payment-section label {
    flex: 1;
    font-weight: bold;
    margin-right: 10px;
    color: #555;
  }
  
  .payment-section span {
    flex: 2;
    color: #000000;
  }
  
  .payment-section ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .payment-section li {
    margin-bottom: 5px;
    color: #000000;
    text-align: right;
  }
  
  .payment-section button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .payment-section button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 768px) {
    .payment-section {
      padding: 15px;
    }
  
    .payment-section h2 {
      font-size: 22px;
    }
  
    .payment-section label,
    .payment-section span {
      font-size: 16px;
    }
  
    .payment-section li {
      font-size: 16px;
      text-align: left;
    }
  }


  .courses-label {
    font-weight: bold;
    margin-bottom: 5px;
        color: #000000;
    text-align: left;
  }
  

  .course-onboarding {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 10%;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .content {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .congrats-header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .course-onboarding {
      padding: 10px;
    }
  
    .header,
    .content,
    .congrats-header {
      font-size: 18px;
    }
  }
  

  .back-button {
    display: block;
  }


  .user-info {
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 2px rgb(3, 3, 3);
    padding: 20px;
    margin-bottom: 20px;
    color: #000000;
  }
  
  .user-info h4 {
    margin: 0;
    font-size: 20px;
    color: #000000;
  }
  
  .user-info h2 {
    margin-top: 20px;
    font-size: 24px;
    color: #000000;
  }
  
  .user-info hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 10px 0;
  }
  
 

  .course {
    background-color: #fff;
    color: #000;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .course:hover {
    background-color: #007bff;
    color: #fff;
  }
  


  /* Add your CSS styling for the Course Dashboard here */
.course-dashboard {
  display: flex;
  flex-direction: row;
}

.course-navbar {
  flex: 1;
  padding: 20px;
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.course-contents {
  margin-top: 20px;
}

.course-contents ul {
  list-style-type: none;
  padding: 0;
}

.course-contents li {
  margin-bottom: 10px;
}

.course-sections {
  flex: 3;
  padding: 20px;
}

.get-certificate {
  padding: 20px;
}



.button-course {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.button-course:hover {
  background-color: #0056b3;
}
