body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.course-dashboard {
    display: flex;
    flex-direction: column;
    
}

.course-navbar {
    background-color: #121481;
    color: #fff;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
   
}

.profile-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-info img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-content {
    margin-top: 20px;
}

.sidebar-content div {
    cursor: pointer;
    margin-bottom: 10px;
}

.course-sections {
    padding: 20px;
}

.sidebar-toggle {
    display: none; /* Hide by default */
}

.sidebar-toggle svg{
    color: #FBF9F1;
}

.theme-toggle {
    display: none;
  }

@media (min-width: 768px) {
    .sidebar-toggle {
        display: block; /* Show for screens larger than 768px */
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 1000; /* Ensure it's above other content */
    }

   

    .sidebar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .course-sections {
        margin-left: 200px;
    }
}

.toggleimage {
    width: 46px;
    height: 56px;
    color: #fff;
    
}

.imgcourse {
    width: 33%;
    height: auto;
}



.General-Dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-bottom: 10%;
}

.profile-info {
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}


.infoname {
  text-align: right;
}

.button-profile-change {
  display: none;
}

.profile-image img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.divider {
  margin: 120px 0;
  border: 3px solid #000000;
}

.course-columns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
 align-items: center;
 text-align: center;
}
.coursesdash {
  display: block;
}

.course {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

@media (max-width: 768px) {
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    margin-bottom: 10px;
  }

  .course-columns {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.selectcourse-link {
  background-color: #3b19ff;
  color: #ffffff;
  padding: 22px 36px;
  font-weight: bold;
  border-radius: 5px;
}

.selectcourse-link:hover {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border: 2px solid black;

}

  .sectiondiv {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 4%;
  }
.logout-link,
.course-selection-button {
    background-color: #3b19ff;
    color: #ffffff;
    padding: 22px 36px;
    font-weight: bold;
    border-radius: 5px;
}

.logout-link:hover,
.course-selection-button:hover {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    border: 2px solid black;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: red;
  text-align: center;
}



.profiledash {
  display: none;
}


