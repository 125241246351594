.course-upload {
    width: 100%;
    margin: 20 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10%;
}

.course-upload h2 {
    text-align: center;
    margin-bottom: 20px;
}

.course-upload label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.course-upload select,
.course-upload input[type="text"],
.course-upload input[type="number"],
.course-upload textarea,
.course-upload button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.course-upload button {
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
}

.course-upload button:hover {
    background-color: #45a049;
}

.upload-module {
    margin-bottom: 20px;
}

.topic-container textarea {
    height: 100px;
    resize: vertical;
}

@media (max-width: 768px) {
    .course-upload {
        padding: 10px;
    }

    .course-upload label,
    .course-upload select,
    .course-upload input[type="text"],
    .course-upload input[type="number"],
    .course-upload textarea,
    .course-upload button {
        font-size: 16px;
    }

    .topic-container textarea {
        font-size: 16px;
    }
}
