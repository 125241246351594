.signup-section {
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
    margin: 12%;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .signup-options {
    text-align: center;
  }
  
  .signup-options h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .signup-options p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .signup-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    justify-content: center;
  }
  
  .signup-links a {
    display: block;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .signup-links a:hover {
    background-color: #0056b3;
  }
  
  
  
  .login-option p {
    color: #333; 
    font-size: 26px; 
  }
  
  .login-option a {
    color: #007bff; /* Set the link color to blue */
    text-decoration: none; /* Remove underline from the link */
  }
  
  .login-option a:hover {
    text-decoration: underline; /* Add underline on hover for better visual feedback */
  }
  


  .signup-page {
    width: 100%; /* Ensure full width on all devices */
    max-width: 400px; /* Set a maximum width for larger screens */
    margin: 0 auto; /* Center the form horizontally */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    width: 100%;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .signup-page {
      padding: 15px;
    }
  
    input[type="text"],
    input[type="email"] {
      font-size: 14px;
    }
  
    button {
      font-size: 14px;
    }
  }

.signup-section h2 {
  font-weight: bold;
  margin-bottom: 20px;
  color: #0950e9;
}

/* Signup Section */

.signup-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  max-width: 800px;
}

/* Image Section */

.image-section {
  flex-basis: 40%;
  margin-right: 20px;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

/* Form Section */

.form-section {
  flex-basis: 60%;
  padding: 20px;
}

.form-section input[type="text"],
.form-section input[type="email"],
.form-section select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-section label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.signup-section input[type="text"],
.signup-section input[type="email"],
.signup-section select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.signup-section input[type="checkbox"] {
  margin-right: 0.5rem;
}

.signup-section button[type="submit"] {
  background-color: #4CAF50;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.form-section label {
  display: block;
  margin-bottom: 10px;
}

.form-section button[type="submit"] {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-section button[type="submit"]:hover {
  background-color: #3e8e41;
}


.signup-section textarea {
 width: 700px;
  height: 10vh; /* Set your desired height here */
  padding: 10px; /* Optional: Add padding for better readability */
  box-sizing: border-box; /* Ensure padding is included in the width */
  resize: none; /* Prevent resizing */
}


/* Optional text box label */
.signup-section .optional-label {
  font-size: 0.8rem;
  color: #777;
}


/* Responsive Design */

@media (max-width: 768px) {
 .signup-section {
    flex-direction: column;
  }
 .image-section {
    margin-right: 0;
    margin-bottom: 20px;
  }
 .image-section img {
    border-radius: 10px;
  }
 .form-section {
    padding: 10px;
  }
}

@media (max-width: 480px) {
 .signup-section {
    padding: 20px;
  }
 .image-section img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
 .form-section input[type="text"],
 .form-section input[type="email"],
 .form-section select {
    padding: 5px;
    margin-bottom: 10px;
  }
 .form-section button[type="submit"] {
    padding: 5px 10px;
  }
}