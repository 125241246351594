/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

h2{
    margin: 10px 0;
    color: #3520f5;
    font-weight: bolder;
    
}
.user-info-course h4 {
    margin: 10px 0;
    color: #0300c7;
    font-weight: bolder;
    
}

h3
 {
    margin: 10px 0;
    color: #000000;
    font-weight: bolder;
    
}

h5 {
    margin: 10px 0;
    color: #000000;
    font-weight: bolder;
    font-size: 25px;
}
button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 10px;
}

.container-course-board {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.user-info-course, .course-details, .user-feedback {
    background: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.user-info-course h4, .user-feedback h4 {
    margin: 5px 0;
}

/* Responsive Styles */
@media (min-width: 768px) {
    .container-course-board {
        flex-direction: row;
        justify-content: space-between;
    }

    .user-info-course, .user-feedback {
        flex: 1;
        margin-right: 10px;
    }

    .course-details {
        flex: 2;
        margin-left: 10px;
    }
}

@media (max-width: 767px) {
    .user-info-course, .course-details, .user-feedback {
        margin-right: 0;
        margin-left: 0;
    }
}

.course-details {
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.course-details h4 {
    font-size: 24px;
    margin-bottom: 10px;
}

.course-details ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.course-details li {
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.course-details li:last-child {
    border-bottom: none;
}

.course-details li h5 {
    font-size: 20px;
    margin-bottom: 5px;
}

.course-details li p {
    margin: 0;
    font-size: 16px;
    color: #000000;
}

.user-feedback textarea {
    margin-bottom: 10px;
}

.toastify-container-course-board {
    z-index: 9999;
}
