@import url('https://fonts.googleapis.com/css2?family=Jacquard+24+Charted&family=Sedan+SC&display=swap');

.certificate-container {
    width: 80%;
    margin: 0 auto;
    padding: 40px;
    border: 5px solid #000;
    border-radius: 20px;
    text-align: center;
    background-color: #ffffff;
    font-family: "Sedan SC", serif;
}

.certificate-container img.logo {
    height: 100px;
    margin-bottom: 20px;
}

.certificate-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.certificate-container .recognition {
    font-size: 1.6rem;
    margin-bottom: 20px;
    text-align: center;
}

.certificate-container .infoname {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Sedan SC", serif;
    font-weight: bolder;
}

.wespan {
    font-family: "Sedan SC", serif;
    color: #000;
}

.certificate-container .course-title {
    font-size: 1.3em;
    margin-bottom: 20px;
    text-align: center;
}

.certificate-container h2 {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.certificate-container .organization-name {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: left;
}

.sign .head .signature-name {
    margin-bottom: 20px;
    text-align: left;
}

.right-section p {
    text-align: left;
}

.signature-name {
    margin-bottom: 6.5%;
    text-align: left;
}

.certificate-container .right-section {
    text-align: left;
    margin-top: 30px;
}

.businessid {
    text-align: left;
}

.certificate-container .right-section .business-id {
    font-size: 1.2em;
    margin-bottom: 50px;
    text-align: left;
}

.certificate-container .right-section .signature {
    height: 90px;
    width: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.dividerline {
    margin: 32px 10px;
    color: #000;
    border: 1px solid #000000;
    display: none;
}

.signature {
    float: right;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    height: 90px;
    width: auto;
    text-align: left;
}

.right-section.imagesign {
    position: relative;
    display: inline-block;
    text-align: left;
}

.right-section.imagesign img.signature {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 10px;
}

.right-section.imagesign .text {
    margin-top: 100px;
}

.right-section p {
    text-align: right;
}

.certificate-container .right-section .signature-name {
    margin-top: 0;
    font-size: 1em;
    font-weight: bold;
}

.certificate-container .button-container {
    margin-top: 30px;
}

.certificate-container .button-container button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.certificate-container .button-container button:hover {
    background-color: #0056b3;
}

@media print {
    .button-container {
        display: none;
    }
}

@media (max-width: 768px) {
    .certificate-container {
        width: 95%;
        padding: 20px;
    }

    .certificate-container h1 {
        font-size: 2em;
    }

    .certificate-container .recognition,
    .certificate-container .course-title,
    .certificate-container .organization-name,
    .certificate-container .businessid,
    .certificate-container .right-section .signature-name {
        font-size: 1em;
    }

    .certificate-container .infoname {
        font-size: 2em;
    }

    .certificate-container .right-section .signature {
        height: 70px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .right-section.imagesign .text {
        margin-top: 70px;
    }

    .certificate-container .button-container button {
        padding: 8px 16px;
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .certificate-container {
        width: 100%;
        padding: 10px;
    }

    .certificate-container h1 {
        font-size: 1.5em;
    }

    .certificate-container .recognition,
    .certificate-container .course-title,
    .certificate-container .organization-name,
    .certificate-container .businessid,
    .certificate-container .right-section .signature-name {
        font-size: 0.8em;
    }

    .certificate-container .infoname {
        font-size: 1.5em;
    }

    .certificate-container .right-section .signature {
        height: 50px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .right-section.imagesign .text {
        margin-top: 50px;
    }

    .certificate-container .button-container button {
        padding: 6px 12px;
        font-size: 0.8em;
    }
}

.Lshapetopleft {
    top: 0;
    right: 0;
    border-bottom: 10px solid #000;
    border-left: 10px solid #0521bb;
    float: right;
    height: 34vh;
    width: 10%;
    transform: rotate(180deg);
}

.Lshapebottomleft {
    bottom: 0;
    left: 0;
    border-top: 10px solid #000;
    border-left: 10px solid #0521bb;
    border-right: 10px solid #000;
    border-bottom: 10px solid #0521bb;
    float: left;
    height: auto;
    width: 100%;
}

.certificateverifiy {
    font-style: normal;
    font-family: 'Courier New', Courier, monospace;
    text-align: right;
    margin: 0;
}


.format-select {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.download-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

.awardcert img{
    width: 400px;
    height: auto;
}