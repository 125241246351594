.admin-login-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 10%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.emailadmin {
    display: block;
}
.input-container {
    margin-bottom: 20px;
}

.input-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-container button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 480px) {
    .admin-login-container {
        width: 90%;
    }
}



.admin-course-upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .user-profile {
    display: flex;
    align-items: center;
  }
  
  .user-image {
    margin-right: 20px;
  }
  
  .user-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .default-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  
  .user-info {
    flex: 1;
  }
  
  .text-editor-container {
    margin-top: 20px;
  }
  
  .text-editor {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .text-editor h3 {
    margin-top: 0;
  }
  
  .text-editor textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    margin-bottom: 10px;
  }

  
  /* Assuming TextEditor is a textarea, you can customize this CSS as needed */

.text-editor {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .text-editor h4 {
    margin-top: 0;
  }
  
  .text-editor textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    margin-bottom: 10px;
  }
  

  .course-upload {
    margin-top: 20px;
  }
  
  .upload-module {
    margin-top: 20px;
  }
  
  .upload-module label {
    display: block;
    margin-bottom: 5px;
  }
  
  .upload-module input[type="text"],
  .upload-module textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .course-add-lm {
    margin-top: 20px;
  }
  .upload-button {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-button:hover {
    background-color: #45a049;
}